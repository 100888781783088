<template>
  <div>
    <SHeader
      @download="onDownload"
      :channel="channel"
      :isAbout="true"
    ></SHeader>
    <div class="about">
      <div class="bghead">
        <img class="left-bg" src="../assets/images/aboutUs/bghead.png" alt="" />
        <img
          class="right-bg"
          src="../assets/images/aboutUs/bgright.png"
          alt=""
        />
      </div>
      <div class="content">
        <!-- 关于UPUP -->
        <div class="page">
          <div class="left-body">
            <div class="about-upup">
              <img src="../assets/images/aboutUs/aboutupup.png" alt="" />
              <div class="about-upup-text">
                <span> UPUPOO动态壁纸桌面是一款多功能桌面美化软件， </span
                ><span>
                  又译为啊噗啊噗，自2017年开始创建，主要面对二次元及年轻受众。 </span
                ><span>
                  覆盖平台有电脑（win11、win10、win8、win7）、手机/平板（Android）。 </span
                ><span>
                  其中PC端除海量精美动静态壁纸外，还包含电脑系统美化功能和丰富的高效 </span
                ><span>
                  小组件，用户可以在美化电脑桌面的同时，高效使用电脑。
                </span>
              </div>
            </div>
          </div>
          <div class="right-body">
            <div class="about-upup-img">
              <!-- <div :class="['about-upup-img', ani1 ? 'ani1' : '']" ref="ref1"> -->
              <img
                src="../assets/images/aboutUs/aboutupup_img.png"
                alt=""
                :class="ani1 ? 'ani1' : ''"
                ref="ref1"
              />
            </div>
          </div>
        </div>

        <!-- 官方账号 -->
        <div class="page">
          <div class="left-body">
            <div class="account-img">
              <img
                src="../assets/images/aboutUs/account_img.png"
                alt=""
                :class="ani2 ? 'ani1' : ''"
                ref="ref2"
              />
            </div>
          </div>
          <div class="right-body">
            <div class="account">
              <img src="../assets/images/aboutUs/account.png" alt="" />
              <div class="QR-code">
                <div class="tiktok">
                  <img src="../assets/images/aboutUs/tiktok.png" alt="" />
                  <span>抖音</span>
                </div>
                <div class="wechat">
                  <img src="../assets/images/aboutUs/wechat.png" alt="" />
                  <span>微信公众号</span>
                </div>
              </div>
              <div class="account-text">
                <span>
                  微博：<a href="https://weibo.com/upupoo/"
                    >https://weibo.com/upupoo/</a
                  >
                </span>
                <span>
                  b站：<a href="https://space.bilibili.com/452371941"
                    >https://space.bilibili.com/452371941</a
                  >
                </span>
                <span>
                  知乎：<a href="https://www.zhihu.com/org/upupoo-33"
                    >https://www.zhihu.com/org/upupoo-33</a
                  >
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- 联系我们 -->
        <div class="page">
          <div class="left-body">
            <div class="contact-us">
              <img src="../assets/images/aboutUs/contactus.png" alt="" />
              <div class="contact-us-text">
                <span
                  v-for="(item, key) in connets"
                  :key="key"
                  :class="key == 3 ? 'fake-br' : ''"
                >
                  {{ `${item.name}：${item.value}` }}
                </span>
              </div>
            </div>
          </div>
          <div class="right-body">
            <div class="contact-us-img">
              <img
                src="../assets/images/aboutUs/contactus_img.png"
                alt=""
                :class="ani3 ? 'ani1' : ''"
                ref="ref3"
              />
            </div>
          </div>
        </div>

        <!-- 公司地址 -->
        <div class="page">
          <div class="left-body">
            <div class="address-img">
              <img
                src="../assets/images/aboutUs/address_img.png"
                alt=""
                :class="ani4 ? 'ani1' : ''"
                ref="ref4"
              />
            </div>
          </div>
          <div class="right-body">
            <div class="address">
              <img src="../assets/images/aboutUs/address.png" alt="" />
              <div class="address-text">
                <span>
                  地址：湖北省武汉市东湖新技术开发区金融港一路7号神州
                </span>
                <span> 数码武汉科技园28栋6楼6003-02号 </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SFooter />
  </div>
</template>

<script>
import { apiDownload } from '@/api/channel'
import SFooter from "@/components/SFooter/foot2";
import SHeader from "@/components/SHeader";
export default {
  data() {
    return {
      ani1: true,
      ani2: true,
      ani3: true,
      ani4: true,
      channel: "",
      connets: [
        { name: "问题答疑QQ群", value: "749685017" },
        { name: "客服邮箱", value: "upupoo@upupoo.com" },
        { name: "工作时间", value: "9:30-18:30" },
      ],
    };
  },
  components: {
    SFooter,
    SHeader,
  },
  mounted() {
    let scrollbarEl = this.$scrollbar.wrap;
    scrollbarEl.addEventListener("scroll", () => {
      let dom1 = this.$refs.ref1;
      let dom2 = this.$refs.ref2;
      let dom3 = this.$refs.ref3;
      let dom4 = this.$refs.ref4;
      this.cantSee(dom1, (re) => {
        this.ani1 = !re;
      });
      this.cantSee(dom2, (re) => {
        this.ani2 = !re;
      });
      this.cantSee(dom3, (re) => {
        this.ani3 = !re;
      });
      this.cantSee(dom4, (re) => {
        this.ani4 = !re;
      });
    });
  },
  methods: {
    async onDownload() {
      const { n: channel, name } = this.$route.query
      const resp = await apiDownload({ zsdd: channel || this.channel })
      window.location.href = resp.data.url
    },
    cantSee: (el, fn) => {
      if (el) {
        let rect = el.getBoundingClientRect();
        fn(
          rect.top >=
            (window.innerHeight || document.documentElement.clientHeight)
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  position: relative;
  background-color: rgba(235, 244, 255, 1);
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;

  .bghead {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left-bg {
      position: absolute;
      width: 100%;
      max-width: 1530px;
      top: 0;
      left: 0;
    }

    .right-bg {
      position: absolute;
      top: 82px;
      right: 0;
    }
  }

  .content {
    width: 1300px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .page {
      width: 1300px;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .left-body {
      position: relative;

      .about-upup {
        img {
          margin-top: 126px;
        }

        .about-upup-text {
          margin-top: 56px;

          span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #161616;
            line-height: 16px;
            margin-top: 14px;
          }
        }
      }

      .account-img {
        position: relative;
        margin-top: 249px;
        width: 544px;

        img {
          position: absolute;
          top: 0;
        }
      }

      .contact-us {
        margin-top: 116px;

        .contact-us-text {
          margin-top: 56px;

          .fake-br {
            margin-top: 40px;
          }

          span {
            // width: 112px;
            margin-top: 20px;
            // height: 15px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #161616;
            line-height: 16px;
          }
        }
      }

      .address-img {
        margin-top: 151px;
        position: relative;
        width: 544px;

        img {
          position: absolute;
          top: 0;
        }
      }
    }

    .right-body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .about-upup-img {
        position: relative;
        margin-top: 106px;
        width: 544px;

        img {
          position: absolute;
          top: 0;
        }
      }

      .account {
        margin-top: 229px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .QR-code {
          margin-top: 56px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .tiktok {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 182px;
              height: 182px;
              border: 4px solid #a4cdff;
            }

            span {
              margin-top: 20px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #161616;
              line-height: 24px;
            }
          }

          .wechat {
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 182px;
              height: 182px;
              border: 4px solid #a4cdff;
            }

            span {
              margin-top: 20px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #161616;
              line-height: 24px;
            }
          }
        }

        .account-text {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #161616;
            line-height: 24px;

            a {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #161616;
              line-height: 24px;

              &:hover {
                color: rgba(64, 148, 249, 1);
              }
            }
          }
        }
      }

      .contact-us-img {
        margin-top: 96px;
        position: relative;
        width: 551px;

        img {
          position: absolute;
          top: 0;
        }
      }

      .address {
        margin-top: 151px;
        margin-bottom: 289px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .address-text {
          margin-top: 56px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          span {
            margin-bottom: 14px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #161616;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.ani1 {
  -webkit-animation: move-up 1.5s;
  -moz-animation: move-up 1.5s;
  animation: move-up 1.5s;
}

@-webkit-keyframes move-up {
  from {
    top: 30px;
    visibility: hidden;
    opacity: 0;
  }

  to {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
}

@-moz-keyframes move-up {
  from {
    top: 30px;
    visibility: hidden;
    opacity: 0;
  }

  to {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
}

@keyframes move-up {
  from {
    top: 30px;
    visibility: hidden;
    opacity: 0;
  }

  to {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
}
</style>
