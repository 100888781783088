<template>
  <header class="header">
    <img
      class="header-logo"
      src="../../assets/images/logo2.png"
      alt=""
      @click="goback"
    />
    <div class="spacer"></div>
    <ul class="header-menu">
      <li
        class="header-menu-item"
        :class="{
          active: isAbout,
        }"
        v-for="(item, index) in menu"
        :key="index"
        @click="onMenuClick(item)"
      >
        {{ item.name == "首页" && disableTitle ? "" : item.name }}
      </li>
    </ul>
    <a title="立即下载" :class="['downapp', {'is-baidu': isBaidu}, {'is-qihu': isQihu}, {'is-360': is360}]" @click="$emit('download')" id="bd1">
      <img src="../../assets/images/download.png" alt="" />
      立即下载
    </a>
  </header>
</template>

<script>
export default {
  props: {
    channel: {
      type: Number,
      default: 0,
    },
    disableTitle: {
      type: Boolean,
      default: false,
    },
    isAbout: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    self: null,
    menu: [
      {
        id: 2,
        name: "关于我们",
        url: "about",
      },
    ],
  }),
  computed: {
    isBaidu() {
      return this.channel == 20210426043;
    },
    is360() {
      return this.channel == 15170001125
    },
    isQihu() {
      return this.channel == 20200707006;
    },
  },
  methods: {
    //this.$emit('download')
    onMenuClick(item) {
      location.href = `/${item.url}`;
    },
    goback() {
      location.href = `/`;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  height: 84px;
  font-family: "Microsoft YaHei";
  padding: 0 100px;
  .header-title {
    margin: 0 22px;
    font-size: 16px;
    color: black;
    letter-spacing: 3px;
  }
}
.spacer {
  flex: 1;
}
.header-menu {
  display: flex;
  align-items: center;
  .header-menu-item {
    font-size: 18px;
    font-weight: bold;
    color: #111010;
    padding: 0 27px;
    cursor: pointer;
    position: relative;
    &.active::after {
      content: " ";
      display: block;
      position: absolute;
      width: 32px;
      height: 6px;
      border-radius: 5px;
      background: #020202;
      bottom: -28px;
      left: 50%;
      margin-left: -16px;
    }
  }
}
.downapp {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px;
  line-height: 38px;
  border: 2px solid #2197f9;
  border-radius: 8px;
  font-size: 16px;
  margin: -2px 0 0 30px;
  background: #3da7fe;
  font-family: Microsoft YaHei;
  font-weight: 700;
  color: #fff;
  left: 310px;
  top: 378px;
  cursor: pointer;
  transition: all 0.3s linear;
  img {
    width: 16px;
    height: 16px;
    margin: 0 9px 0 0;
    display: block;
    float: left;
  }
}
</style>
